import React from "react";
import { Grid, Typography } from "@mui/material";

type TitleProps = {
  label: string;
};

const Title: React.FC<TitleProps> = ({ label }) => {
  return (
    <Grid sx={{ textAlign: "center", minHeight: "5rem" }}>
      <Typography sx={{ fontSize: "32px" }}>{label}</Typography>
    </Grid>
  );
};

export { Title };
