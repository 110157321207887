import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

const Buttons: React.FC = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      width="100%"
    >
      <Link to={"/gallery"}>
        <Box
          sx={{
            backgroundColor: "#9fb1a3",
            width: "250px",
            borderRadius: "15px",
            padding: "8px 6px",
            "&:hover": { cursor: "pointer" },
          }}
        >
          <Button
            sx={{
              textTransform: "none",
              border: "2px solid white",
              width: "100%",
              borderRadius: "15px",
            }}
          >
            <Trans
              i18nKey={"photoGallery"}
              components={{
                1: <Typography display="inline" color="#e7fae8" />,
                2: (
                  <Typography
                    display="inline"
                    fontFamily="showg"
                    fontSize="25px"
                    color="#e7fae8"
                  />
                ),
              }}
            />
          </Button>
        </Box>
      </Link>
    </Box>
  );
};

export { Buttons };
