import React from "react";
import { Grid } from "@mui/material";
import { NavigationButton } from "../Input/NavigationButton";
import { useTranslation } from "react-i18next";

export const menuItems = (t: any) => [
  { label: t("navigation.aboutUsButtonLabel"), link: "/about" },
  { label: t("navigation.menuButtonLabel"), link: "/menu" },
  { label: t("navigation.galleryButtonLabel"), link: "/gallery" },
  { label: t("navigation.contactButtonLabel"), link: "/contact" },
];

const Navigation: React.FC = () => {
  const { t } = useTranslation("translation");

  return (
    <Grid container direction={"row"} spacing={1}>
      {menuItems(t).map((item) => (
        <Grid item>
          <NavigationButton label={item.label} link={item.link} />
        </Grid>
      ))}
    </Grid>
  );
};

export { Navigation };
