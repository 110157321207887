import React from "react";
import { Box, Container, Grid, styled, Typography } from "@mui/material";
import { Title } from "../../components/Title";
import { useTranslation } from "react-i18next";
import middleBackground from "../../assets/background/middle-background-no-padding.png";

const ExplanationTitle = styled(Typography)({
  color: "#bc2e69",
  fontSize: "20px",
});

const About = () => {
  const { t } = useTranslation("translation");
  return (
    <Box>
      <Title label={t("aboutUs.ourStoryLabel")} />

      <Box
        sx={(theme) => ({
          backgroundImage: `url(${middleBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          backgroundSize: "100% 100%",
          minHeight: "400px",
          [theme.breakpoints.down("md")]: {
            minHeight: "800px",
            backgroundSize: "100% 100%",
          },
        })}
      >
        <Container sx={{ paddingTop: "80px" }}>
          <Typography
            sx={{ marginBottom: "12px", textAlign: "left", fontSize: "20px" }}
          >
            {t("aboutUs.storyPart1Label")}
          </Typography>
          <Typography
            sx={{ marginBottom: "12px", textAlign: "left", fontSize: "20px" }}
          >
            {t("aboutUs.storyPart2Label")}
          </Typography>
          <Grid container sx={{ paddingTop: "16px", paddingBottom: "16px" }}>
            <Grid
              item
              md={4}
              xs={12}
              sx={{ textAlign: "left", padding: "0px 10px 10px 0px" }}
            >
              <ExplanationTitle>
                {t("aboutUs.healthyAndYummyLabel")}
              </ExplanationTitle>
              <Typography>
                {t("aboutUs.healthyAndYummyExplanationLabel")}
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{ textAlign: "left", padding: "0px 10px 10px 0px" }}
            >
              <ExplanationTitle>{t("aboutUs.tailoredLabel")}</ExplanationTitle>
              <Typography>{t("aboutUs.tailoredExplanationLabel")}</Typography>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{ textAlign: "left", padding: "0px 10px 10px 0px" }}
            >
              <ExplanationTitle>{t("aboutUs.realFruitLabel")}</ExplanationTitle>
              <Typography>{t("aboutUs.realFruitExplanationLabel")}</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export { About };
