import React from "react";
import { Box } from "@mui/material";
import { Menu } from "../../components/Menu";
import { Explanation } from "../../components/Explanation";
import { Buttons } from "../../components/Buttons";

const Home = () => {
  return (
    <Box>
      <Explanation />
      <Menu />
      <Buttons />
    </Box>
  );
};

export { Home };
