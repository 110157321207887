import React from "react";
import { Box, Container, Typography } from "@mui/material";

const NoPage = () => (
  <Box>
    <Container>
      <Typography>404 not found</Typography>
    </Container>
  </Box>
);

export { NoPage };
