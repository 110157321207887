import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import leaf from "../../assets/leaves.png";
import cone from "../../assets/cone.png";
import circle from "../../assets/circle.png";
import bottomBackground from "../../assets/background/bottom-background.png";

type ExplanationElementProps = {
  title: string;
  primaryColor?: string;
  primarySize?: string;
  secondaryColor?: string;
  secondarySize?: string;
};

const ExplanationElement: React.FC<ExplanationElementProps> = ({
  title,
  primaryColor,
  primarySize,
  secondaryColor,
  secondarySize,
}) => {
  return (
    <Box display="grid" sx={{ paddingBottom: "8px" }}>
      <Box display="flex" alignItems="center">
        <img
          src={leaf}
          width="35px"
          alt="leaf"
          style={{ marginRight: "10px" }}
        />
        <Trans
          i18nKey={title}
          components={{
            1: (
              <Typography
                display="inline"
                sx={{ fontSize: "16px", textAlign: "left" }}
              />
            ),
            2: (
              <Typography
                display="inline"
                sx={(theme) => ({
                  fontFamily: "showg",
                  ...(primarySize ? { fontSize: primarySize } : null),
                  color: primaryColor,
                })}
              />
            ),
            ...(secondarySize || secondaryColor
              ? {
                  3: (
                    <Typography
                      display="inline"
                      sx={(theme) => ({
                        fontFamily: "showg",
                        ...(secondarySize ? { fontSize: secondarySize } : null),
                        ...(secondaryColor ? { color: secondaryColor } : null),
                      })}
                    />
                  ),
                }
              : null),
          }}
        />
      </Box>
    </Box>
  );
};

// const BackgroundCone: React.FC = () => {
//   return (
//     <Box
//       width="500px"
//       height="fit-content"
//       alignItems="center"
//       justifyContent="center"
//       sx={{
//         backgroundImage: `url(${circle})`,
//         backgroundRepeat: "no-repeat",
//         backgroundPosition: "center",
//         backgroundSize: "80% 50%",
//       }}
//     >
//       <img src={cone} alt="cone" height="700px" width="100%" />
//     </Box>
//   );
// };

const Explanation: React.FC = () => {
  const { t } = useTranslation("translation");

  return (
    <Box>
      <Container>
        <Box>
          <Box marginBottom={"50px"}>
            <Box textAlign="left">
              <Trans
                i18nKey="title"
                components={{
                  1: <Typography display="inline" sx={{ fontSize: "35px" }} />,
                  2: (
                    <Typography
                      display="inline"
                      sx={{ fontFamily: "showg", fontSize: "35px" }}
                    />
                  ),
                }}
              />
            </Box>
            <Box textAlign="left">
              <Typography>{t("subTitle")}</Typography>
            </Box>
          </Box>
          <ExplanationElement
            title={"explanation.deliciousTreatsLabel"}
            primaryColor="#6f8873"
            primarySize="20px"
            secondaryColor="#2d623b"
          />
          <ExplanationElement
            title={"explanation.organicLabel"}
            primaryColor="#9fb1a3"
            primarySize="24px"
          />
          <ExplanationElement
            title={"explanation.sugarLessLabel"}
            primaryColor="#2d623b"
            primarySize="12px"
            secondaryColor="#777a77"
            secondarySize="22px"
          />
          <ExplanationElement
            title={"explanation.realFruitsLabel"}
            primaryColor="#9fcba7"
            primarySize="12px"
          />
          <ExplanationElement
            title={"explanation.tasteLabel"}
            primaryColor="#939b94"
            primarySize="20px"
          />
          <ExplanationElement
            title={"explanation.glutenFreeLabel"}
            primaryColor="#6fa178"
            primarySize="20px"
            secondaryColor="#405843"
            secondarySize="22px"
          />
        </Box>
      </Container>
    </Box>
  );
};

export { Explanation };
