import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import middleBackground from "../../assets/background/middle-background.png";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Slider from "react-slick";
import { Products } from "../../constants/products";
import { CardMenu } from "../DataDisplay/CardMenu";

const Menu = () => {
  const { t } = useTranslation("translation");
  const Theme = useTheme();
  const isMobile = useMediaQuery(Theme.breakpoints.down("md"));

  const settings = {
    dots: false,
    infinite: true,
    centerMode: !isMobile,
    arrows: false,
    autoplay: true,
    speed: 500,
    slidesToShow: isMobile ? 2 : 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplaySpeed: 2000,
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${middleBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0% 0%",
        backgroundSize: "100% 100%",
        minHeight: isMobile ? "600px" : "800px",
        alignContent: "center",
      }}
    >
      <Container sx={{ alignItems: "center" }}>
        <Box justifyContent={"flex-start"} width={"fit-content"}>
          <Trans
            i18nKey={"ourMenuLabel"}
            components={{
              0: <Typography display="inline" />,
              1: (
                <Typography
                  display="inline"
                  sx={(theme) => ({
                    fontSize: 25,
                    fontFamily: "showg",
                    color: theme.palette.titlePurpleColor.main,
                  })}
                />
              ),
            }}
          />
        </Box>
        <Slider {...settings}>
          {Products.map((product) => (
            <CardMenu
              image={product.image}
              alt={product.alt}
              title={product.title}
              size={isMobile ? "150" : "300"}
            />
          ))}
        </Slider>
        <Box pt={"30px"}>
          <Typography sx={{ fontFamily: "timeburnerbold", fontSize: "25px" }}>
            {t("eatingOrganicLabel")}
          </Typography>
          <Trans
            i18nKey={"optionLabel"}
            components={{
              1: (
                <Typography
                  sx={{ fontFamily: "timeburnerbold", fontSize: "25px" }}
                />
              ),
              2: (
                <Typography
                  display="inline"
                  sx={(theme) => ({
                    fontSize: 25,
                    fontFamily: "showg",
                    color: theme.palette.titlePurpleColor.dark,
                  })}
                />
              ),
            }}
          />
          <Trans
            i18nKey={"privilegeLabel"}
            components={{
              1: (
                <Typography
                  sx={{ fontFamily: "timeburnerbold", fontSize: "25px" }}
                />
              ),
              2: (
                <Typography
                  display="inline"
                  sx={(theme) => ({
                    fontSize: "20px",
                    fontFamily: "showg",
                    color: theme.palette.titlePurpleColor.light,
                  })}
                />
              ),
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export { Menu };
