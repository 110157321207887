import React from "react";
import {
  Card,
  CardMedia,
  CardActionArea,
  Typography,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";

type CardMenuProps = {
  image: string;
  alt: string;
  title: string;
  size: string;
};

const CardMenu: React.FC<CardMenuProps> = ({ image, alt, title, size }) => {
  return (
    <Box width={"fit-content"}>
      <Link to={"/menu"} style={{ textDecoration: "none" }}>
        <Card
          sx={{
            minWidth: `${size}px`,
            backgroundColor: "transparent",
            borderRadius: "35px",
            boxShadow: "none",
          }}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              height={size}
              image={image}
              alt={alt}
              sx={{ borderRadius: "35px" }}
            />
          </CardActionArea>
        </Card>
        <Typography
          sx={{
            paddingTop: "10px",
            fontFamily: "timeburnerbold",
            color: "black",
          }}
        >
          {title}
        </Typography>
      </Link>
    </Box>
  );
};

export { CardMenu };
