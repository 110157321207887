import React from "react";
import { Grid } from "@mui/material";
import menuCoffee from "../../assets/menu-coffee-2.jpg";
import menuWeb from "../../assets/menu-web-page-2.jpg";
import { useTranslation } from "react-i18next";
import { Title } from "../../components/Title";

const MenuPage = () => {
  const { t } = useTranslation("translation");
  return (
    <Grid>
      <Title label={t("menu.ourMenu")} />
      <Grid container>
        <Grid item xs={12} md={6}>
          <img src={menuWeb} alt="menu-coffe" width="100%" />
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={menuCoffee} alt="menu-coffe" width="100%" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export { MenuPage };
