import lolly from "../assets/lolly.jpg";
import miniCone from "../assets/mini-cone.jpg";
import moelleux from "../assets/moelleux.jpg";
import smoothie from "../assets/smoothie.jpg";

export const Products = [
  {
    image: lolly,
    alt: "lolly",
    title: "Batonnets fruités",
  },
  {
    image: miniCone,
    alt: "mini-cone",
    title: "Tornade glacée",
  },
  {
    image: moelleux,
    alt: "moelleux",
    title: "Moelleux au chocolat",
  },
  {
    image: smoothie,
    alt: "smoothie",
    title: "Smoothie ou Milkshake",
  },
];
