import React from "react";
import { Box } from "@mui/material";
import { ContactForm } from "../../components/Forms/ContactForm";

const Contact = (props: any) => {
  return (
    <Box>
      <ContactForm />
    </Box>
  );
};

export { Contact };
