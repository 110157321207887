import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Backdrop,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import EmailJs from "@emailjs/browser";
import toast from "react-hot-toast";
import { Title } from "../../Title";

type Inputs = {
  name: string;
  surname: string;
  number: string;
  address: string;
  email: string;
  message: string;
};

const ContactForm: React.FC = () => {
  const { t } = useTranslation("translation");

  const [loader, setLoader] = React.useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { isSubmitSuccessful },
    reset,
  } = useForm<Inputs>({
    defaultValues: {
      email: "",
      message: "",
    },
  });
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoader(true);
    EmailJs.send("service_g6hm99j", "template_xcfuw1e", data, {
      publicKey: "ZQXhrLULc9zM1TiJw",
    }).then(
      () => {
        console.log("SUCCESS!");
        toast.success("Email sent successfully.");
        setLoader(false);
      },
      (error) => {
        console.error("FAILED...", error.text);
        toast.error("Email failed");
        setLoader(false);
      },
    );
  };

  React.useEffect(() => {
    if (isSubmitSuccessful && !loader) {
      reset({ email: "", message: "" });
      window.scrollTo(0, 0);
    }
  }, [isSubmitSuccessful, loader, reset]);

  return (
    <Box>
      <Container maxWidth="md">
        <Title label={t("contactForm.title")} />
        <Typography sx={{ paddingBottom: "20px" }}>
          {t("contactForm.subtitle")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" rowGap={5} textAlign="left">
            <Grid item md={4}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    inputProps={{ type: "email", required: true }}
                    label={field.name}
                    name={field.name}
                    onChange={field.onChange}
                    value={field.value}
                  />
                )}
              />
            </Grid>
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  multiline
                  rows={10}
                  label={field.name}
                  name={field.name}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
            <Grid item textAlign={"right"}>
              <Button type="submit" variant="contained" color="primary">
                {t("buttonLabel.submitLabel")}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </Box>
  );
};

export { ContactForm };
