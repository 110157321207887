import React from "react";
import {
  Box,
  Container,
  Dialog,
  ImageList,
  ImageListItem,
  Slide,
} from "@mui/material";

import gallery1 from "../../assets/gallery/gallery1.jpg";
import gallery2 from "../../assets/gallery/gallery2.jpg";
import gallery3 from "../../assets/gallery/gallery3.jpg";
import gallery4 from "../../assets/gallery/gallery4.jpg";
import gallery5 from "../../assets/gallery/gallery5.jpg";
import gallery6 from "../../assets/gallery/gallery6.jpg";

import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";
import { TransitionProps } from "@mui/material/transitions";
import { Title } from "../../components/Title";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Gallery = () => {
  const { t } = useTranslation("translation");

  function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const images = [
    {
      original: gallery1,
      alt: "gallery1",
      rows: 2,
      cols: 2,
    },

    {
      original: gallery2,
      alt: "gallery2",
    },
    {
      original: gallery3,
      alt: "gallery3",
    },
    {
      original: gallery4,
      alt: "gallery4",
      rows: 2,
      cols: 2,
    },
    {
      original: gallery5,
      alt: "gallery5",
    },
    {
      original: gallery6,
      alt: "gallery6",
    },
  ];

  return (
    <Container>
      <Title label={t("gallery.ourPhotos")} />
      <ImageList variant="quilted" cols={4} rowHeight={121}>
        {images.map((item) => (
          <ImageListItem
            key={item.original}
            cols={item.cols || 1}
            rows={item.rows || 1}
          >
            <img
              {...srcset(item.original, 121, item.rows, item.cols)}
              alt={item.alt}
              loading="lazy"
              onClick={handleClickOpen}
              style={{ cursor: "pointer" }}
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Box>
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <ImageGallery
            items={images}
            showPlayButton={false}
            showFullscreenButton={false}
          />
        </Dialog>
      </Box>
    </Container>
  );
};

export { Gallery };
