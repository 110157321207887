import React from "react";
import "./App.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./i18n";
import { Home } from "./containers/home";
import { Header } from "./components/Header";
import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Contact } from "./containers/contact";
import { About } from "./containers/about";
import { Gallery } from "./containers/gallery";
import { NoPage } from "./containers/noPage";
import { MenuPage } from "./containers/menu";
import { Toaster } from "react-hot-toast";
import { Location } from "./containers/location";

declare module "@mui/material/styles" {
  interface Palette {
    navigationButtonColor: Palette["primary"];
    titlePurpleColor: Palette["primary"];
    greenColor: Palette["primary"];
  }
  interface PaletteOptions {
    navigationButtonColor: PaletteOptions["primary"];
    titlePurpleColor: PaletteOptions["primary"];
    greenColor: PaletteOptions["primary"];
  }
}

const theme = createTheme({
  palette: {
    navigationButtonColor: {
      main: "#9A6886",
    },
    titlePurpleColor: {
      main: "#9b3f76",
      dark: "#70234e",
      light: "#855871",
    },
    greenColor: {
      main: "#6f8873",
      dark: "#2d623b",
      light: "#9fb1a3",
      contrastText: "#a0cba7",
    },
  },
  typography: {
    fontFamily: "timeburnerbold, arial, showg , sans-serif",
  },
});

const AllRoutes = () => {
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="contact" element={<Contact />} />
      <Route path="about" element={<About />} />
      <Route path="gallery" element={<Gallery />} />
      <Route path="menu" element={<MenuPage />} />
      <Route path="location" element={<Location />} />
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
};

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div className="App" style={{ overflowX: "hidden" }}>
          <Toaster position="bottom-right" reverseOrder={false} />
          {/*HEADER*/}
          <Header />
          {/*ALL ROUTES*/}
          <AllRoutes />
          {/*FOOTER*/}
          <Footer />
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
