import React from "react";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

type NavigationButtonType = {
  label: string;
  link: string;
};

const NavigationButton: React.FC<NavigationButtonType> = ({ label, link }) => {
  return (
    <Box>
      <Link to={link}>
        <Button
          sx={(theme) => ({
            backgroundColor: theme.palette.navigationButtonColor.main,
            color: "white",
            boxShadow: 1,
            height: "25px",
            minWidth: "110px",
            "&:hover": {
              backgroundColor: theme.palette.navigationButtonColor.main,
              opacity: "0.95",
              transition: "0.6s",
            },
          })}
        >
          {label}
        </Button>
      </Link>
    </Box>
  );
};

export { NavigationButton };
