import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import bottomBackground from "../../assets/background/bottom-background.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type FooterLinkProps = {
  label: string;
  url: string;
};

const FooterLink: React.FC<FooterLinkProps> = ({ label, url }) => (
  <Link style={{ textDecoration: "none" }} to={url}>
    <Typography sx={{ color: "black" }}>{label}</Typography>
  </Link>
);

const Footer: React.FC = () => {
  const { t } = useTranslation("translation");

  return (
    <Box
      sx={(theme) => ({
        position: "relative",
        backgroundImage: `url(${bottomBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: "100% 100%",
        minHeight: "300px",
        [theme.breakpoints.down("md")]: {
          minHeight: "300px",
        },
      })}
    >
      <Container>
        <Grid
          container
          direction="column"
          rowGap="10px"
          sx={{
            position: "absolute",
            textAlign: "left",
            bottom: 50,
          }}
        >
          <FooterLink label={t("footer.findUsLabel")} url={"/location"} />
          <FooterLink label={t("navigation.menuButtonLabel")} url={"/menu"} />
          <FooterLink
            label={t("navigation.contactButtonLabel")}
            url={"/contact"}
          />
        </Grid>
      </Container>
    </Box>
  );
};

export { Footer };
