import React from "react";
import {
  Box,
  Container,
  useMediaQuery,
  useTheme,
  Button,
  Menu,
  MenuItem,
  Fade,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { menuItems, Navigation } from "../Navigation";
import logo from "../../assets/byy-logo.png";
import topBackground from "../../assets/background/top-background.png";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MobileNavigation: React.FC = () => {
  const { t } = useTranslation("translation");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const router = useLocation();

  console.log(router);

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon sx={{ color: "black" }} />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {menuItems(t).map((item) => (
          <Link
            to={item.link}
            style={{
              textDecoration: "none",
              color: router.pathname === item.link ? "#9b3f76" : "black",
            }}
          >
            <MenuItem>{item.label}</MenuItem>
          </Link>
        ))}
      </Menu>
    </div>
  );
};

const Header = () => {
  const Theme = useTheme();
  const isMobile = useMediaQuery(Theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        backgroundImage: `url(${topBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        backgroundSize: "100% 85%",
      }}
    >
      <Container>
        <Box
          sx={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box alignContent={"center"}>
            <Link to="/">
              <img width={isMobile ? "200px" : "250px"} src={logo} alt="logo" />
            </Link>
          </Box>
          <Box alignContent={"center"}>
            {isMobile ? <MobileNavigation /> : <Navigation />}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export { Header };
