import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Title } from "../../components/Title";
import { useTranslation } from "react-i18next";
import gallery6 from "../../assets/gallery/gallery6.jpg";

const Location = () => {
  const { t } = useTranslation("translation");
  const Theme = useTheme();
  const isMobile = useMediaQuery(Theme.breakpoints.down("md"));

  return (
    <Box>
      <Title label={t("location")} />
      <Typography>{t("locationAddress")}</Typography>
      <Box>
        <img
          src={gallery6}
          height={isMobile ? "250px" : "550px"}
          alt="gallery7"
          style={{ boxShadow: "0px 5px 5px grey" }}
        />
      </Box>
    </Box>
  );
};

export { Location };
